/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { sanitize } from '../../utils/functions';
import { isEmpty } from 'lodash';

/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import ArticleCard from '../../components/blogs/article-card';
// import Subscribe from '../../components/blogs/subscribe';
import ContactForm from '@blocks/contact-form';

import RelatedPosts from '../../components/blogs/related-posts';
import SocialShare from '../../components/social-share';
import SpotIMComments from '../../components/spot-im-comments';
import SingleSubscribe from '../../components/blogs/single-subscribe';

import './style.scss';
import SEO from "../../components/seo";

/**
 * Single Post.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const Single = ({ pageContext, uri }) => {

	const { id, databaseId: postId, title, content, relatedPosts, form, singlePostHubSpotForm } = pageContext;
	const articleClassNames = `post-${postId} post type-post status-publish format-standard hentry category-react tag-accessibility tag-gatsby entry`;
	const { seo, siteUrl, postUri } = pageContext;

	const openGraphImage = (!isEmpty(pageContext.large) && !isEmpty(pageContext.large.sourceUrl)) ? pageContext.large.sourceUrl : '';

	return (
		<Layout title={title} uri={uri} className="single single-post" >
			<SEO
				title={title}
				seoData={seo}
				uri={postUri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<article data-id={id} id={`post-${postId}`} className={articleClassNames}>
				<ArticleCard
					post={pageContext}
					isSinglePage={true}
					className="article-card--article-header"
					animate={false}
					isWide={true}
				/>
				<div className="entry-content post-container" dangerouslySetInnerHTML={{ __html: sanitize(content) }} />
				<div className="post-container">
					<SingleSubscribe form={singlePostHubSpotForm} />
				</div>
				<div className="post-container">
					<SocialShare title={title} link={`${siteUrl}${postUri}`} />
				</div>
			</article>
			<RelatedPosts posts={relatedPosts} />
			<SpotIMComments postId={postId} uri={uri} />
			{/* <Subscribe form={form} tagName="Blog_PostSubmit" tagParent="BLOG" /> */}
			<ContactForm />
		</Layout>
	);
};

Single.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default Single;
