/**
 * External dependencies.
 */
import React from 'react';

/**
 * XIcon Component.
 *
 * @return {jsx}
 */
const XIcon = () => (
	<svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M21.9637 7.96875H24.8594L18.5348 15.1957L25.975 25.0312H20.1508L15.5857 19.0676L10.3686 25.0312H7.46875L14.2322 17.2998L7.09961 7.96875H13.0715L17.1936 13.4197L21.9637 7.96875ZM20.9465 23.3004H22.5502L12.1979 9.60938H10.4752L20.9465 23.3004Z" fill="black" />
	</svg>

);

export default XIcon;
