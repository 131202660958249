/**
 * External dependencies.
 */
import React, { useState } from 'react';
import Proptypes from 'prop-types';
import HubspotForm from 'react-hubspot-form';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import { sanitize } from '../../../utils/functions';

import './style.scss';

/**
 * SingleSubscribe Component.
 *
 * @return {jsx}
 */
const SingleSubscribe = ( { form } ) => {
	const [ submitted, setSubmitted ] = useState( false );
	const { formHeadline, formPortalid, formId, thankYouMessage, downloadLink, downloadLabel } = form;

	if ( ! formId ) {
		return null;
	}

	const containerClass = classnames( 'ow-single-subscribe', {
		'ow-single-subscribe--form-submitted' : submitted,
	} );

	return (
		<div className={ containerClass }>
			<div className="row align-center">
				<div className="small-12 medium-9 large-10 column">
					{
						! submitted ? <h2 className="ow-single-subscribe__title" dangerouslySetInnerHTML={ { __html: sanitize( formHeadline ) } } /> : null
					}
				</div>
			</div>
			<div className="row align-center">
				<div className="small-12 medium-9 large-10 column">
					<div className="ow-single-subscribe__form">
						{
							<>
								<HubspotForm
									portalId={ formPortalid }
									formId={ formId }
									onSubmit={ () => {
										setSubmitted( true )
									} }
								/>
								{
									submitted ? (
										<div className="thankyou-message">
											<div dangerouslySetInnerHTML={ { __html: sanitize( thankYouMessage ) } } />
											<a className="single-cpt__download-media" href={ downloadLink } rel="noopener noreferrer" target="_blank" download>{ downloadLabel }</a>
										</div>
									) : null
								}
							</>
						}
					</div>
				</div>
			</div>
		</div>
	);
};

SingleSubscribe.propTypes = {
	form: Proptypes.object,
};

SingleSubscribe.defaultProps = {
	form: {
		title: '',
		portalId: '',
		formId: '',
	}
}

export default SingleSubscribe;
