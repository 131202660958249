/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

/**
 * Internal dependencies.
 */
import FacebookIcon from '../icons/facebook-icon';
import TwitterIcon from '../icons/twitter-icon';
import XIcon from '../icons/x-icon';
import LinkedinIcon from '../icons/linkedin-icon';

import './style.scss';

/**
 * SocialShare Component.
 *
 * @param {string} title Article title text.
 * @param {string} sectionTitle Section Title. Default is 'Share this article'.
 *
 * @return {jsx}
 */
const SocialShare = ({ title, sectionTitle, link }) => {

	if (isEmpty(sectionTitle)) {
		sectionTitle = 'Share this article';
	}

	if (isEmpty(link)) {
		link = 'undefined' !== typeof window ? window.location.href : '';
	}

	const linkedinLink = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(link)}&title=${encodeURIComponent(title)}`;
	const twitterLink = `https://x.com/intent/tweet?url=${encodeURIComponent(link)}&text=${encodeURIComponent(title)}`;
	const facebookLink = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(link)}&title=${encodeURIComponent(title)}`;

	return (
		<div className="social-share">
			<h6 className="social-share__title">{sectionTitle}</h6>
			<ul>
				<li>
					<a className="social-share__linkedin" target="_blank" rel="noopener noreferrer" href={linkedinLink}>
						<LinkedinIcon />
					</a>
				</li>
				<li>
					<a className="social-share__twitter" target="_blank" rel="noopener noreferrer" href={twitterLink}>
						<XIcon />
					</a>
				</li>
				<li>
					<a className="social-share__facebook" target="_blank" rel="noopener noreferrer" href={facebookLink}>
						<FacebookIcon />
					</a>
				</li>
			</ul>
		</div>
	);
};

SocialShare.propTypes = {
	title: PropTypes.string,
	sectionTitle: PropTypes.string,
};

SocialShare.defaultProps = {
	title: '',
	sectionTitle: '',
};

export default SocialShare;
