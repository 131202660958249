/**
 * External dependencies.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies.
 */
import { isBrowser, leadingSlashIt } from '../../utils/functions';
import { spotID } from '../../../client-config';

/**
 * SpotIMComments Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const SpotIMComments = ( props ) => {

	const { uri, articleTags, postId } = props;
	const [ loading, setLoading ] = useState( false );

	const tags = articleTags.join( ', ' );
	const postUrl = isBrowser() ? window.location.origin + leadingSlashIt( uri ) : '';

	const loadScript = () => {

		// Load script only once in the entire SPA.
		if ( true === window.spotIMScriptAdded || true === loading ) {
			return false;
		}

		setLoading( true );

		const script = document.createElement('script');

		script.async = true
		script.onload = () => {
			setLoading( false );
		};
		script.src = `https://launcher.spot.im/spot/${ spotID }`;
		script.dataset.spotimModule = 'spotim-launcher';

		document.head.appendChild( script );
		window.spotIMScriptAdded = true;
	};

	useEffect( () => { loadScript() }, [] ); // eslint-disable-line

	return (
		<div
			data-spotim-module="conversation"
			data-post-url={ postUrl }
			data-article-tags={ tags }
			data-post-id={ postId }
		/>
	);
};

SpotIMComments.propTypes = {
	uri: PropTypes.string,
	articleTags: PropTypes.array,
	postId: PropTypes.number,
};

SpotIMComments.defaultProps = {
	uri: '',
	articleTags: [],
	postId: 0,
};

export default SpotIMComments;
